<template>
  <ion-page id="page-content">
    <ion-modal @didDismiss="showSort = false" :initialBreakpoint="$store.state.deviceHeight < 700 ? 0.45 : 0.4" :is-open="showSort" css-class="trans-sort-modal">
      <div class="modal-content trans-sort">
        <div class="title-box">
          <span class="close" @click="showSort = false"></span>
          <span class="font-18 main-color font-b">Select month</span>
          <span class="font-14 sub-color" @click="monthSelect">Apply</span>
        </div>
        <ul class="list-box">
          <li>
            <p class="font-16 label-color">This Month</p>
            <button class="check-btn" @click="monthPick = 0" :class="{ active: monthPick == 0 }"></button>
          </li>
          <li>
            <p class="font-16 label-color">Past month</p>
            <button class="check-btn" @click="monthPick = 1" :class="{ active: monthPick == 1 }"></button>
          </li>
          <li>
            <p class="font-16 label-color">Past 2 Months</p>
            <button class="check-btn" @click="monthPick = 2" :class="{ active: monthPick == 2 }"></button>
          </li>
          <li>
            <p class="font-16 label-color">Past 3 Months</p>
            <button class="check-btn" @click="monthPick = 3" :class="{ active: monthPick == 3 }"></button>
          </li>
        </ul>
      </div>
    </ion-modal>
    <ion-content :fullscreen="true">
      <topbar :bigtitle="true" title="Transaction History" :class="{ 'letter-ios-05': $store.state.deviceType == 1 }" :showBack="true"></topbar>
      <div class="transaction-page margin-top-160">
        <div class="sort-box">
          <img style="margin-right: 10px" src="../assets/icon-calendar.png" />
          <span @click="showSort = true" class="font-16 sub-color font-m"> Select month </span>
        </div>
        <ul class="list-box">
          <li v-for="(transaction, index) in transactionList" :key="index">
            <div class="detail">
              <p class="font-18 main-color font-m Medium">{{ transaction.storeName }}</p>
              <p class="font-16 gery-color font-m Medium">Date: {{ transaction.transDateTime ? transaction.transDateTime.replaceAll("-", "/") : transaction.transDateTime }}</p>
              <p class="font-16 gery-color font-m Medium">Transaction id: {{ transaction.transNo }}</p>
            </div>
            <p class="price font-24 main-color font-m Medium">${{ transaction.transPayAmt }}</p>
          </li>
        </ul>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonModal } from "@ionic/vue";
import topbar from "@/components/topbar.vue";
import config from "../config";
export default {
  name: "transHistory",
  mixins: [config.globalMixin],
  components: { IonContent, IonPage, topbar, IonModal },
  data() {
    return {
      showSort: false,
      pageNum: 0,
      pageLimit: 20,
      transactionList: [],
      monthPick: 0,
      startTime: this.$store.state.nowMonthStart,
      endTime: this.$store.state.nowMonthEnd,
      monthList: ["January", "February", "March", "April", "May", "June", "July", "August", "September"],
    };
  },
  methods: {
    backEvent() {
      this.$router.push({
        path: "/main/me",
      });
    },
    async init() {
      const objdata = this.getAuthObj();
      objdata.apiUrl = this.apiConfig.getTransactionList;
      objdata.pageNum = this.pageNum;
      objdata.pageLimit = this.pageLimit;
      objdata.startTime = this.startTime;
      objdata.endTime = this.endTime;
      const data = await this.$store.dispatch("apiGetEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.transactionList = data.data.result;
      this.transactionList.forEach(item => {
        //   transAmt
        item.transPayAmt += "";
        if (item.transPayAmt.indexOf(".") < 0) {
          item.transPayAmt += ".00";
        } else {
          let num = item.transPayAmt.split(".")[1].length;
          for (let i = num; i < 2; i++) {
            item.transPayAmt += "0";
          }
        }
      });
    },
    monthSelect() {
      switch (this.monthPick) {
        case 0:
          this.startTime = this.$store.state.nowMonthStart;
          this.endTime = this.$store.state.nowMonthEnd;
          break;
        case 1:
          this.startTime = this.$store.state.lastMonthStart;
          this.endTime = this.$store.state.lastMonthEnd;
          break;
        case 2:
          this.startTime = this.$store.state.pastMonthStart;
          this.endTime = this.$store.state.pastMonthEnd;
          break;
        case 3:
          this.startTime = this.$store.state.lPastMonthStart;
          this.endTime = this.$store.state.lPastMonthEnd;
          break;
      }
      this.showSort = false;
      this.init();
    },
  },
  ionViewWillEnter() {
    this.init();
    this.$store.state.exitApp = false;
    this.sendGAPageView("Transaction History");
  },
};
</script>
